#content {
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  flex-flow: row wrap;
  padding: 24px 0;
}

.station {
  padding: 12px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 25vw;
  min-width: 260px;
  margin-left: 12px;
  margin-top: 12px;
  border-radius: 8px;
}

p {
  margin: 0;
  padding: 0;
}

.title {
  font-size: 20px;
  margin-bottom: 12px;
}

.datapoint {
  text-align: left;
}
/*# sourceMappingURL=station.a032639d.css.map */
